import { useState } from 'react';
// Types
import { GetStream_stream } from 'api/streams/types/GetStream';
import { StreamStatus } from 'api/graphql-global-types';
// Helpers
import { parseForInnerHTML } from 'helpers/textEditor';
import { getIsInterview } from 'helpers/getIsInterview';
import {
  getStreamDateV2,
  getStreamSocialShareText,
  getStreamerName,
} from 'helpers/streams';
import { computeOrderStreamPath, getEnvLink } from 'helpers/routes';
// Hooks
import { useGetCurrUser } from 'hooks';
import { useAthleteRelatedStreams } from 'hooks/useAthleteRelatedStreams';
// Constants
import { MEMORABILIA, MERCH, PRODUCTS } from 'constants/routes';
// Components
import Section from 'components/common2/Section/Section';
import RelatedStreams from 'components/Athlete/Streams/RelatedStreams/RelatedStreams';
import StreamParticipants from 'components/OrderStream/StreamParticipants/StreamParticipants';
import StreamNavigation from 'components/Athlete/Streams/StreamNavigation/StreamNavigation';
import RelatedProfileProductsList from 'components/Athlete/components/RelatedProfileProductsList/RelatedProfileProductsList';
import StreamVideoPlayer from './StreamVideoPlayer/StreamVideoPlayer';
import NewsLetter from 'components/common2/NewsLetter/NewsLetter';
import SocialShareQRButton from 'components/common2/SocialShareQRButton/SocialShareQRButton';
import TipStore from 'components/WatchStream/UnfinishedStream/components/WatcherView/components/StreamActions/TipStore/TipStore';
import MentionsView from 'components/MentionsView/MentionsView';
// UI
import Icon from 'ui/Icon/Icon';
// UI2
import HashTags from 'ui2/HashTags/HashTags';
import LinkButton from 'ui2/LinkButton/LinkButton';
import Button from 'ui2/Button/Button';
import RedesignedModal from 'ui2/RedesignedModal/RedesignedModal';
// Styles
import styles from './StreamsPlaylist.module.scss';

type StreamsPlaylistProps = {
  stream: GetStream_stream;
  refetchStream: () => void;
};

const StreamsPlaylist = ({
  stream,
  refetchStream,
}: StreamsPlaylistProps): JSX.Element | null => {
  const isStreamEnded = stream.streamStatus === StreamStatus.Ended;
  const recordsList = stream?.streamRecords || [];
  const [storeTipModalIsOpen, setStoreTipModalIsOpen] = useState<boolean>(
    false
  );
  const { hasProducts, hasMemorabilia } = stream?.store || {};
  const { data: userData } = useGetCurrUser();

  const { streams } = useAthleteRelatedStreams({
    storeId: stream?.store?.id || '',
    currentStreamId: stream?.id,
    totalStreamsToShow: 10,
  });

  const isUserStreamCreator = userData?.me?.id === stream.store?.id;
  const streamerName = getStreamerName(stream.store);
  const socialShareButtonText = getStreamSocialShareText(
    isUserStreamCreator,
    isStreamEnded,
    streamerName
  );

  const seeAllLink = hasProducts
    ? hasMemorabilia
      ? `/${stream?.store?.slug}${MEMORABILIA}`
      : `/${stream?.store?.slug}${PRODUCTS}`
    : `/${stream?.store?.slug}${MERCH}`;

  if (!recordsList?.length) {
    refetchStream();
    return null;
  }

  const handleStoreTipModalToggle = () => {
    setStoreTipModalIsOpen((prev) => !prev);
  };

  const merchLink = hasProducts
    ? `${getEnvLink()}${MERCH}?athleteIds=${stream?.store?.id}`
    : `${getEnvLink()}/${stream.store.slug}${MERCH}`;

  const isInterview = getIsInterview(stream);

  const isOwner = userData?.me?.id === stream?.store?.id;
  const downloadLink = stream?.streamRecords
    ? stream?.streamRecords[0]?.videoURL
    : '';

  return (
    <>
      <Section
        title=""
        backgroundTitle="stream"
        className={styles.streamPlaylist}
      >
        {stream && <StreamNavigation stream={stream} />}

        <StreamVideoPlayer stream={stream} refetchStream={refetchStream} />

        <div className={styles.infoWrapper}>
          <div className={styles.infoBlock}>
            <h1 className={styles.title}>{stream.name || ''}</h1>
            <h2 className={styles.createdAt}>
              {isStreamEnded && 'was live on '}
              {getStreamDateV2(stream.scheduleDate, stream.timeZone)}
            </h2>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html: parseForInnerHTML(stream.description),
              }}
            ></div>
            <HashTags tags={stream.hashtags} />
            <MentionsView mentions={stream.mentions} />
          </div>

          <div className={styles.shopButtons}>
            {!isInterview && (
              <>
                <Button
                  color="harvest-gold"
                  xs="xSmall"
                  onClick={handleStoreTipModalToggle}
                  className={styles.tipButton}
                >
                  <Icon name="tipHand" className={styles.tipIcon} />
                  Tip {stream.store.role}
                </Button>
                <LinkButton
                  color="harvest-gold"
                  href={merchLink}
                  target="_blank"
                  className={styles.buyButton}
                >
                  <Icon name="buyCart" className={styles.buyCartIcon} />
                  Shop featured products
                </LinkButton>
              </>
            )}

            {isOwner && (
              <LinkButton
                color="harvest-gold"
                href={downloadLink || ''}
                target="_blank"
                className={styles.buyButton}
              >
                <Icon name="download" className={styles.buyCartIcon} />
                download
              </LinkButton>
            )}

            {stream && (
              <SocialShareQRButton
                icon
                itemTitle={stream?.name || ''}
                modalTitle="Share Stream"
                modalText="Share this stream in any way convenient for you"
                path={computeOrderStreamPath(stream)}
                twitterText={socialShareButtonText || ''}
              />
            )}
          </div>
        </div>

        {stream && <StreamParticipants streamId={stream?.id} />}
        <RelatedStreams streams={streams} />
      </Section>
      {hasProducts && (
        <Section
          title=""
          backgroundTitle="My products"
          seeAllLink={seeAllLink}
          seeAllLinkTitle="view more"
        >
          <RelatedProfileProductsList
            className={styles.relatedProducts}
            storeId={stream?.store?.id || ''}
            title="My Products"
            showOnlyProducts
            showOnlyMemorabilia
          />
        </Section>
      )}

      <NewsLetter />

      <RedesignedModal
        title={`Tip ${stream.store.storeDetails?.storeName}`}
        open={storeTipModalIsOpen}
        onCancel={handleStoreTipModalToggle}
        focusTrapped={false}
        theme="black"
      >
        <TipStore onCloseModal={handleStoreTipModalToggle} />
      </RedesignedModal>
    </>
  );
};

export default StreamsPlaylist;
