import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { useRouter } from 'next/router';
import { WatchQueryOptions } from '@apollo/client';
// Types
import { GetStream, GetStreamVariables } from 'api/streams/types/GetStream';
// Hooks
import {
  useHasMounted,
  useAppContext,
  useGetCurrUser,
  useGetMyPaymentMethods,
} from 'hooks';
// Ui2
import Text from 'ui3/Text/Text';
import Loader from 'ui3/Loader/Loader';
import Button from 'ui3/Button/Button';
import NotificationModal from 'ui3/NotificationModal/NotificationModal';
// Components
import PaymentCards from 'components/common3/PaymentCards/PaymentCards';
import AddPaymentModal from 'components/common3/AddPaymentModal/AddPaymentModal';
// Styles
import styles from './ProductPayment.module.scss';

type WithoutModalProps = {
  onSelect?: Dispatch<SetStateAction<string>>;
  isSuccessModalVisible?: never;
  setSuccessModalVisibility?: never;
  successModalTitle?: never;
  onSuccessRedirectPath?: never;
  goToLabel?: never;
  successPaymentModalDescription?: never;
  updateStreamQuery?: <TVars = GetStreamVariables>(
    mapFn: (
      previousQueryResult: GetStream,
      options: Pick<WatchQueryOptions<TVars, GetStream>, 'variables'>
    ) => GetStream
  ) => void;
};

type WithModalProps = {
  onSelect?: Dispatch<SetStateAction<string>>;
  isSuccessModalVisible: boolean;
  setSuccessModalVisibility: Dispatch<SetStateAction<boolean>>;
  successModalTitle: string;
  onSuccessRedirectPath?: string;
  goToLabel?: string;
  successPaymentModalDescription?: string | JSX.Element;
  isFree?: boolean;
  theme?: Theme;
  updateStreamQuery?: <TVars = GetStreamVariables>(
    mapFn: (
      previousQueryResult: GetStream,
      options: Pick<WatchQueryOptions<TVars, GetStream>, 'variables'>
    ) => GetStream
  ) => void;
};

type PaymentProps = WithoutModalProps | WithModalProps;

const ProductPayment = ({
  onSelect,
  isSuccessModalVisible,
  setSuccessModalVisibility,
  successModalTitle,
  onSuccessRedirectPath,
  successPaymentModalDescription,
  goToLabel,
  updateStreamQuery,
}: PaymentProps) => {
  const { setLoginModalVisibility } = useAppContext();

  const hasMounted = useHasMounted();
  const router = useRouter();
  const { data: userData } = useGetCurrUser();
  const { data: paymentData, loading } = useGetMyPaymentMethods();

  // used to disable buy buttona after deleting last payment method on product page
  useEffect(() => {
    if (!paymentData?.myPaymentMethods?.length && onSelect) {
      onSelect('');
    }
  }, [paymentData, onSelect]);

  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);

  const onPaymentModalOpen = () => {
    if (userData) {
      setIsOpenPaymentModal(true);
    } else {
      setLoginModalVisibility(true);
    }
  };

  const onPaymentModalClose = () => setIsOpenPaymentModal(false);

  const handleSuccessPaymentModalClose = () => {
    if (setSuccessModalVisibility) {
      setSuccessModalVisibility(false);
    }

    if (updateStreamQuery) {
      updateStreamQuery((data) => ({
        ...data,
        stream: {
          ...data.stream,
          isPurchased: true,
        },
      }));
    }
  };

  const handleSuccessPaymentModalRedirect = () => {
    if (updateStreamQuery) {
      updateStreamQuery((data) => ({
        ...data,
        stream: {
          ...data.stream,
          isPurchased: true,
        },
      }));
    }

    if (setSuccessModalVisibility) {
      setSuccessModalVisibility(false);
      if (onSuccessRedirectPath) {
        router.push(onSuccessRedirectPath);
      }
    }
  };

  return (
    <>
      <div className={styles.root}>
        <p className={styles.header}>Payment Method</p>

        {hasMounted && loading && <Loader loading size="xLarge" />}

        {!paymentData?.myPaymentMethods?.length ? (
          <Text>{"You don't have a payment card yet."}</Text>
        ) : (
          <PaymentCards
            data={paymentData?.myPaymentMethods}
            onPaymentSelect={onSelect}
          />
        )}
        <div className={styles.footer}>
          <Button
            className={styles.footerCta}
            onClick={onPaymentModalOpen}
            type="button"
          >
            Add Payment Method
          </Button>
        </div>
      </div>

      <AddPaymentModal
        isOpen={isOpenPaymentModal}
        onClose={onPaymentModalClose}
        userEmail={userData?.me?.email || ''}
      />
      <NotificationModal
        open={!!isSuccessModalVisible}
        type="success"
        title={successModalTitle || ''}
        withCancelButton
        description={successPaymentModalDescription || ''}
        onCancelClick={handleSuccessPaymentModalClose}
        onClose={handleSuccessPaymentModalClose}
        acceptLabel={goToLabel || ''}
        cancelLabel="Continue shopping"
        onAcceptClick={handleSuccessPaymentModalRedirect}
      />
    </>
  );
};

export default ProductPayment;
